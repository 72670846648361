export default function LogoIcon() {
  return (
    <div className="flex flex-row space-x-3">
      <div className="flex flex-col justify-center">
        <p className="outfit-font font-bold text-base text-[#367b2c] mt-2">
          Tu tienda
        </p>
      </div>
      <div className="flex flex-col space-y-10 items-center justify-center ">
        <div className="flex flex-row">
          <img src="/img/logo_icon.svg" className="max-h-[34px]" />
        </div>
      </div>
    </div>
  );
}
