import { PromoCard } from "components/common/promo-card";

export const WeekPromos = ({ handleClick }: { handleClick: () => void }) => {
  return (
    <section className="bg-[#F4F5F5] min-h-screen p-8 flex flex-col sm:p-24 sm:min-h-fit">
      <div className="flex flex-col items-center sm:relative">
        <h2 className="outfit-font text-[#324561] font-bold text-4xl text-center sm:text-5xl">
          ¡Aprovecha las ofertas* <br /> de la semana!
        </h2>
        <figure className="sm:absolute sm:-right-24 sm:-top-40 lg:-right-24 lg:-top-32">
          <img
            src="/img/ahorra_mas.svg"
            className="w-2/3 mx-auto my-6 sm:m-0 lg:w-5/6"
            alt="¡Ahorra más! Productos hasta 40% más baratos que en el súper de tu barrio. Calidad garantizada."
          />
        </figure>
      </div>
      <div className="sm:grid sm:gap-x-2.5 sm:grid-cols-3 max-w-screen-2xl mx-auto lg:gap-x-32">
        <PromoCard
          imageUrl="/img/promo_card_1.webp"
          productName="Leche entera 1 L Colún"
          leaderPrice={1240}
          price={1090}
          handleClick={handleClick}
        />
        <PromoCard
          imageUrl="/img/promo_card_2.webp"
          productName="Mantequilla con sal 125gr"
          leaderPrice={1680}
          price={1190}
          handleClick={handleClick}
        />
        <PromoCard
          imageUrl="/img/promo_card_3.png"
          productName="Aceite de maravilla 1 L Chef"
          leaderPrice={3590}
          price={2690}
          handleClick={handleClick}
        />
      </div>
      <div className="flex flex-col items-center">
        <button
          className="bg-[#367B2C] text-white outfit-font uppercase tracking-wider font-medium py-2 px-4 rounded-full sm:my-8 transition-transform duration-300 transform hover:scale-125 mt-4 mb-12"
          onClick={handleClick}
        >
          Comprar ahora
        </button>
        <p className="outfit-font text-[#324561] text-sm text-center">
          *Precios se actualizan cada semana. Consulta disponibilidad.
        </p>
      </div>
    </section>
  );
};
