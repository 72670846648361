import { ProsCard } from "components/common/pros-card";

export const WhyBuyFromUs = () => {
  return (
    <section className="bg-[#F4F5F5] min-h-screen p-8 flex flex-col sm:p-24 sm:min-h-fit">
      <h2 className="outfit-font text-[#324561] font-bold text-4xl text-center sm:text-5xl">
        ¿Por qué comprar en Canasta?
      </h2>
      <div className="sm:grid sm:gap-x-2.5 sm:grid-cols-3 max-w-screen-2xl mx-auto lg:gap-x-24 lg:mt-8">
        <ProsCard
          title="Ahorra tiempo y compra online"
          paragraph="Sin filas eternas, sin apuros, sin gastos en transporte."
          image="/img/card_1.webp"
          imageClasses="lg:bg-top"
        />
        <ProsCard
          title="Gratis a tu casa"
          paragraph="Recibe tu canasta martes o jueves*, en tu casa sin costos de envío."
          image="/img/card_2.webp"
        />
        <ProsCard
          title="Ofertas todas las semanas"
          paragraph="Compra productos esenciales más baratos que en el super."
          image="/img/card_3.jpeg"
        />
      </div>
      <p className="outfit-font text-[#324561] text-sm text-center">
        *Martes: Puente Alto y San José de Maipo. Jueves: San Bernardo, Quinta
        Normal y Maipú.
      </p>
    </section>
  );
};
