export default function GuaranteedQualityIcon() {
  return (
    <div className="flex flex-col space-y-10 items-center justify-center ">
      <div className="flex flex-row">
        <img
          src="/img/calidad_garantizada.svg"
          className="max-w-[120px] sm:max-w-[150px] -mt-32 sm:-mt-36"
        />
      </div>
    </div>
  );
}
