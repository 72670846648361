import { getSeller } from "actions/seller/getSeller";
import { Seller } from "domain/entities/Seller";
import CommunesRM from "lib/communes";
import { useRouter } from "next/router";
import { useState, useEffect, useCallback } from "react";

const useStoreModel = () => {
  const router = useRouter();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [seller, setSeller] = useState<Seller | null>(null);
  const storeName = router.query.storeName;

  useEffect(() => {
    setLoading(true);
    if (storeName) {
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      getSeller({ storeName })
        .then(({ data }) => {
          setSeller({
            ...data,
            commune:
              CommunesRM.find(({ id }) => data.commune === id) || undefined,
          });
        })
        // @ts-expect-error TS(2345): Argument of type '"No se ha encontrado la tienda d... Remove this comment to see the full error message
        .catch(() => setError("No se ha encontrado la tienda del vendedor."))
        // @ts-expect-error TS(2345): Argument of type 'void' is not assignable to param... Remove this comment to see the full error message
        .finally(setLoading(false));
    }
  }, [storeName]);

  const handleClickBasket = useCallback(() => {
    if (seller)
      router.push(
        `/login?seller_email=${seller.email}&seller_id=${seller.store_name}`,
      );
  }, [seller, router]);

  return {
    loading: loading,
    seller,
    error,
    handleClickBasket,
  };
};

export default useStoreModel;
