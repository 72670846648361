const CommunesRM = [
  { name: "Cerro Navia", id: "cerro-navia" },
  { name: "Conchalí", id: "conchali" },
  { name: "El Bosque", id: "el-bosque" },
  { name: "Estación Central", id: "estacion-central" },
  { name: "Independencia", id: "independencia" },
  { name: "La Florida", id: "la-florida" },
  { name: "Maipú", id: "maipu" },
  { name: "Ñuñoa", id: "nunoa" },
  { name: "Puente Alto", id: "puente-alto" },
  { name: "Quinta Normal", id: "quinta-normal" },
  { name: "Renca", id: "renca" },
  { name: "San Bernardo", id: "san-bernardo" },
  { name: "San José de Maipo", id: "san-jose-de-maipo" },
  { name: "San Miguel", id: "san-miguel" },
  { name: "Santiago", id: "santiago" },
];

export default CommunesRM;
