import { IconamoonDeliveryFreeLight } from "public/icons/DeliveryFree";

export const Banner = () => {
  return (
    <div className="p-4 pb-0 lg:max-w-7xl m-auto">
      <div className="text-md text-accent p-4 border-2 border-primary rounded-xl">
        <div className="flex">
          <IconamoonDeliveryFreeLight />
          &ensp;
          <p className="outfit-font text-base lg:text-lg">
            <b>¡Asegura tu despacho!</b>
          </p>
        </div>
        <p className="outfit-font text-justify text-sm lg:text-base">
          Haz tu pedido antes del lunes a las 14:00 para entrega a partir del
          martes (Puente Alto y San José), o antes del miércoles a las 14:00 para entrega a partir del
          jueves (San Bernardo, Quinta Normal y Maipú).
        </p>
      </div>
    </div>
  );
};
