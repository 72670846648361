import axios from "axios";

export const getSeller = ({
  sellerId,
  storeName
}: any) => {
  if (sellerId)
    return axios.get(
      process.env.NEXT_PUBLIC_API_BASEPATH + "api/admin/seller/" + sellerId,
    );
  if (storeName)
    return axios
      .get(process.env.NEXT_PUBLIC_API_BASEPATH + "api/admin/seller", {
        params: { store_name: storeName, is_active: true },
      })
      .then((resp) => ({ ...resp, data: resp.data?.data[0] }));

  return null;
};
export const listSellers = ({
  isActive,
  page,
  limit
}: any) =>
  axios.get(process.env.NEXT_PUBLIC_API_BASEPATH + "api/admin/seller", {
    params: { is_active: isActive, page, limit },
  });
