import { useState } from "react";

export const Accordion = ({
  question,
  answer,
}: {
  question: string;
  answer: string;
}) => {
  const [open, setOpen] = useState(false);

  return (
    <div className="">
      <div className="border-b-2 border-[#324561] my-4 py-2">
        <button
          className="flex justify-between w-full"
          onClick={() => setOpen(!open)}
        >
          <p className="outfit-font font-bold text-[#324561] text-lg text-left leading-tight">
            {question}
          </p>
          {open ? (
            <span className="font-black">-</span>
          ) : (
            <span className="font-black">+</span>
          )}
        </button>
        <div
          className={`grid overflow-hidden transition-all duration-500 ease-in-out ${
            open ? " grid-rows-[1fr] opacity-100" : "grid-rows-[0fr] opacity-0"
          }`}
        >
          <div className="overflow-hidden outfit-font text-lg text-[#324561] mt-4 leading-tight">
            {answer}
          </div>
        </div>
      </div>
    </div>
  );
};
