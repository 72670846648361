export const PromoCard = ({
  imageUrl,
  productName,
  price,
  leaderPrice,
  handleClick,
  imageClasses,
}: {
  imageUrl: string;
  productName: string;
  price: number;
  leaderPrice: number;
  handleClick: () => void;
  imageClasses?: string;
}) => {
  return (
    <div
      className="card bg-[#F2ECE6] my-8 shadow-lg hover:cursor-pointer transition-transform duration-300 transform hover:scale-110"
      onClick={handleClick}
    >
      <figure className="relative bg-white">
        <img src={imageUrl} className={`max-w-72 max-h-44 ${imageClasses}`}  />
      </figure>
      <div className="card-body text-[#324561]">
        <h4 className="font-medium outfit-font uppercase text-center sm:text-lg">
          {productName}
        </h4>
        <p className="outfit-font uppercase text-center line-through text-[#909394] md:text-sm">
          Precio Super{" "}
          {new Intl.NumberFormat("es-CL", {
            style: "currency",
            currency: "CLP",
          }).format(leaderPrice)}
        </p>
        <div className="mx-auto bg-red-600 px-4 rounded-full">
          <span className="outfit-font text-[#F2ECE6] text-2xl font-bold sm:text-xl">
            {new Intl.NumberFormat("es-CL", {
              style: "currency",
              currency: "CLP",
            }).format(price)}
          </span>
        </div>
      </div>
    </div>
  );
};
