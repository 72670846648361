import { Step } from "components/common/step";
import { Seller } from "domain/entities/Seller";

export const HowToBuy = ({ seller }: { seller: Seller }) => {
  return (
    <section className="bg-[#F2ECE6] min-h-screen p-8 flex flex-col sm:min-h-fit sm:p-24">
      <h2 className="outfit-font text-[#324561] font-bold text-4xl text-center sm:mb-4">
        ¿Cómo comprar en Canasta Ahorro?
      </h2>
      <div className="flex flex-col items-center sm:grid sm:grid-cols-[1fr_0.3fr_1fr_0.3fr_1fr] sm:items-start">
        <Step
          seller={seller}
          imageUrl="img/seller-dummy.webp"
          title="Entra a la tienda"
          text="Ingresa al link de mi tienda y completa con tus datos para empezar a comprar."
          highlight="¡Es seguro!"
          imageClasses="object-cover rounded-full"
        />
        <figure className="rotate-90 sm:rotate-0 sm:mt-20 sm:mx-2 lg:mt-28">
          <img src="/img/arrow.svg" className="lg:mx-auto" />
        </figure>
        <Step
          imageUrl="/img/agregar.png"
          title="Arma tu pedido"
          alt="Agregar"
          text="Consulta por las ofertas de Canasta Ahorro."
          highlight="¡Rápido que se agotan!"
          imageClasses="object-contain"
        />
        <figure className="rotate-90 sm:rotate-0 sm:mt-20 sm:mx-2 lg:mt-28">
          <img src="/img/arrow.svg" className="lg:mx-auto" />
        </figure>
        <Step
          imageUrl="/img/canasta_step.png"
          title="Recibe tu canasta"
          text="¡Listo! Tu canasta llegará a tu casa (martes o jueves), con envío gratis y adecuado a tus horarios."
          imageClasses="object-cover rounded-full"
        />
      </div>
    </section>
  );
};
