import GuaranteedQualityIcon from "components/common/guaranteed-quality-icon";

export const AboutUs = () => {
  return (
    <section className=" bg-[#324561] text-white lg:min-h-fit">
      <div className="flex flex-col-reverse items-center p-8 sm:p-24 justify-evenly lg:grid lg:grid-cols-[1fr_3fr] max-w-screen-2xl mx-auto">
        <div className="sm:my-12 my-4">
          <img
            src="/img/about_us_canasta.svg"
            className="sm:w-full sm:h-full"
          />
          <div className="relative right-32 sm:right-64 md:right-52 lg:right-32">
            <GuaranteedQualityIcon />
          </div>
        </div>
        <div className="lg:px-24">
          <h2 className="outfit-font text-4xl font-bold sm:text-5xl">
            ¿Qué es Canasta Ahorro?
          </h2>
          <p className="outfit-font text-lg sm:text-2xl lg:leading-snug">
            <br />
            Somos una plataforma de venta comunitaria que vende productos del
            super a nuestros vecinos.
            <br />
            <br />
            Permitimos a miles de familias ahorrar en sus compras semanales y a
            personas disponer de un ingreso extra sin salir de sus barrios.
          </p>
        </div>
      </div>
    </section>
  );
};
