import { Seller } from "domain/entities/Seller";

export const Step = ({
  seller,
  imageUrl,
  alt,
  title,
  text,
  highlight,
  imageClasses,
}: {
  seller?: Seller;
  imageUrl?: string;
  alt?: string;
  title: string;
  text: string;
  highlight?: string;
  imageClasses?: string;
}) => {
  return (
    <div className="flex flex-col items-center my-4">
      <figure>
        <div className="bg-[#A2D9E1] w-64 h-64 rounded-full relative sm:w-40 sm:h-40 lg:w-64 lg:h-64">
          {seller && seller.image_url ? (
            <img
              className={`w-52 h-52 absolute z-1 top-0 left-0 right-0 bottom-0 m-auto sm:w-32 sm:h-32 lg:w-52 lg:h-52 ${imageClasses}`}
              src={seller.image_url}
              alt={alt}
            />
          ) : (
            <img
              className={`w-52 h-52 absolute z-1 top-0 left-0 right-0 bottom-0 m-auto sm:w-32 sm:h-32 lg:w-52 lg:h-52 ${imageClasses}`}
              src={imageUrl}
              alt={alt}
            />
          )}
        </div>
      </figure>
      <div className="">
        <h4 className="outfit-font text-[#324561] text-xl font-bold text-center my-4 lg:text-2xl">
          {title}
        </h4>
        <p className="outfit-font text-[#324561] text-center lg:text-xl">
          {text} <br />
          <span className="font-bold">{highlight}</span>
        </p>
      </div>
    </div>
  );
};
