export default function VerticalLogo() {
  return (
    <div className="flex flex-col space-y-10 items-center justify-center ">
      <div className="flex flex-row">
        <a
          href="https://canastaahorro.cl/"
          target="_blank"
          aria-label="Canasta Ahorro (abre en otra pestaña)"
        >
          <img src="/img/new_logo_vertical.svg" className="max-w-[100px]" />
        </a>
      </div>
    </div>
  );
}
