import React, { ComponentPropsWithoutRef } from "react";

import { Spinner } from "./Spinner";

interface LoadingContainerProps extends ComponentPropsWithoutRef<"div"> {
  isLoading: boolean;
  errorMessage?: string;
  children?: React.ReactNode;
}

export const LoadingContainer = ({
  isLoading,
  errorMessage,
  children,
}: LoadingContainerProps) => {
  if (isLoading) {
    return (
      <div className="flex flex-col justify-center m-4 p-4 h-full">
        <Spinner />
      </div>
    );
  }
  if (errorMessage) {
    return (
      <div className="m-4 bg-white rounded-md shadow-md p-4">
        <h2 className="text-xl font-bold text-red-600">Error</h2>
        <p>{errorMessage}</p>
      </div>
    );
  }

  return React.createElement(React.Fragment, null, children);
};
