import Link from "next/link";

import useStoreModel from "./StoreModel";


import { AboutUs } from "components/seller-store/AboutUs";
import { Banner } from "components/seller-store/Banner";
import { Faq } from "components/seller-store/Faq";
import { Footer } from "components/seller-store/Footer";
import { HowToBuy } from "components/seller-store/HowToBuy";
import { WeekPromos } from "components/seller-store/WeekPromos";
import { Welcome } from "components/seller-store/Welcome";
import { WhyBuyFromUs } from "components/seller-store/WhyBuyFromUs";
import HorizontalLogo from "components/common/horizontal-logo";
import LogoIcon from "components/common/logo-icon";
import { LoadingContainer } from "components/common-back/Progress/LoadingContainer";

const UpdateProductView = () => {
  const { loading, seller, error, handleClickBasket } = useStoreModel();

  if (error) {
    return (
      <div className="relative flex flex-col bg-[#a2d9e1] pt-14 gap-8">
        <div className="fixed flex w-full h-[60px] top-0 left-0 bg-white px-2 sm:px-10 z-[1] justify-between">
          <HorizontalLogo />
          <LogoIcon />
        </div>
        <div className="">
          <div className="flex flex-col flex-1 justify-center min-h-screen">
            <p className="text-lg sm:text-base md:text-xl lg:text-2xl text-center mt-4 mx-5 sm:mx-10 outfit-font font-medium">
              Lo sentimos, no hemos encontrado la página que buscabas. <br />
              <br />
              ¡Ingresa a nuestra página principal para conocer más sobre Canasta
              Ahorro!
            </p>
            <div className="flex self-center">
              <div className="my-10">
                <Link href="https://www.canastaahorro.cl" passHref>
                  <button className="btn btn-primary text-white text-base tracking-widest rounded-3xl outfit-font bg-[#79c75e] border-[#79c75e] hover:bg-[#367b2c] hover:border-[#367b2c]">
                    Página principal
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }

  return (
    <LoadingContainer isLoading={loading}>
      {seller && (
        <>
        <Banner></Banner>
          <div className="relative flex flex-col bg-[#f4f5f5]">
            <div className="flex w-full h-[60px] top-0 left-0 bg-white px-2 sm:px-10 z-[1] justify-between">
              <HorizontalLogo />
              <LogoIcon />
            </div>
            <Welcome seller={seller} handleClick={handleClickBasket} />
            <AboutUs />
            <WhyBuyFromUs />
            <WeekPromos handleClick={handleClickBasket} />
            <HowToBuy seller={seller} />
            <Faq />
            <Footer />
          </div>
        </>
      )}
    </LoadingContainer>
  );
};

export default UpdateProductView;
