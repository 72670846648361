export const ProsCard = ({
  title,
  paragraph,
  image,
  imageClasses,
}: {
  title: string;
  paragraph: string;
  image: string;
  imageClasses?: string;
}) => {
  return (
    <div className="flex flex-col rounded-2xl bg-[#A2D9E1] my-8 h-96 shadow-lg">
      <div
        className={`flex-grow flex items-center justify-center bg-cover rounded-t-2xl h-48 bg-white bg-center ${imageClasses}`}
        style={{ backgroundImage: `url(${image})` }}
      ></div>
      <div className="flex-grow flex items-center text-[#324561]">
        <div className="p-8 sm:p-4 lg:p-8 h-48">
          <h3 className="card-title outfit-font lg:text-xl">{title}</h3>
          <p className="outfit-font">{paragraph}</p>
        </div>
      </div>
    </div>
  );
};
