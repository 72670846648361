import { Seller } from "domain/entities/Seller";

export const Welcome = ({
  seller,
  handleClick,
}: {
  seller: Seller;
  handleClick: () => void;
}) => {
  return (
    <section className="bg-[#A2D9E1] text-[#324561] min-h-screen md:min-h-fit">
      <div className="p-8 sm:p-24 sm:min-h-fit lg:grid lg:grid-cols-[1fr_3fr] flex flex-col-reverse items-center max-w-screen-2xl mx-auto">
        <div className="h-1/2 lg:h-fit my-4">
          {seller.image_url ? (
            <img
              className="rounded-full mx-auto w-80 h-80 object-cover"
              src={seller.image_url}
            />
          ) : (
            <img
              className="rounded-full mx-auto w-80 h-80 object-cover"
              src="img/seller-dummy.webp"
            />
          )}
        </div>
        <div className="flex flex-col items-center lg:px-24 lg:h-fit">
          <h2 className="outfit-font text-4xl font-bold sm:text-5xl lg:text-6xl lg:leading-snug">
            ¡Bienvenido a la tienda de {seller.first_name}!
          </h2>
          <p className="outfit-font text-lg sm:text-2xl sm:leading-normal lg:leading-snug my-4">
            Compra productos del super hasta un 40% más económicos y recíbelos
            gratis en la comodidad de tu casa.
          </p>
          <button
            className="bg-[#367B2C] text-white outfit-font uppercase tracking-wider font-medium py-2 px-4 rounded-full sm:text-2xl sm:my-4 transition-transform duration-300 transform hover:scale-125"
            onClick={handleClick}
            aria-label="Comprar ahora (redirige a la aplicación de Canasta Ahorro)"
          >
            Comprar ahora
          </button>
        </div>
      </div>
    </section>
  );
};
