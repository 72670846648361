import { Accordion } from "components/common/accordion";

export const Faq = () => {
  return (
    <section className="bg-[#F4F5F5] min-h-screen p-8 sm:p-24 sm:min-h-fit">
      <div className="max-w-screen-md mx-auto">
        <div className="flex flex-row items-center">
          <img src="/img/pineapple.svg" className="sm:mr-4" />
          <h2 className="outfit-font text-[#324561] font-bold text-4xl text-center">
            Preguntas frecuentes
          </h2>
        </div>
        <Accordion
          question="¿Qué es Canasta Ahorro?"
          answer="Canasta Ahorro es una plataforma de venta comunitaria, que te entrega productos esenciales de alta calidad a un precio reducido, diseñado para ayudar a las familias a ahorrar en sus compras diarias."
        />
        <Accordion
          question="¿Dónde puedo comprar los productos de Canasta Ahorro? Y si hago una compra, ¿cuándo llegará a mi casa?"
          answer="Puedes comprar tus canastas en las tiendas virtuales de nuestros vendedores con entregas en diferentes puntos de Santiago (RM). Actualmente, puedes contactar a las vendedoras a través de WhatsApp para hacer tu pedido, y llegará a tu casa los días: martes y jueves entre las 12:00 y las 19:00 h."
        />
        <Accordion
          question="¿Qué métodos de pago aceptan?"
          answer="Desde la plataforma podrás realizar pagos vía tarjetas de crédito o débito, y en otros casos, puedes acordar con tu vendedor comunitario para realizar pagos en efectivo o transferencia bancaria."
        />
        <Accordion
          question="¿Ofrecen algún tipo de descuento o promoción en la compra de productos?"
          answer="Sí, todas las semanas ofrecemos promociones y descuentos especiales en productos esenciales para tu familia. Mantente en contacto con las vendedoras o síguenos en nuestras redes sociales para enterarte de las ofertas vigentes."
        />
        <Accordion
          question="¿Qué hago si un producto del super de Canasta Ahorro está defectuoso o no cumple mis expectativas?"
          answer="Queremos que estés completamente satisfecho con tu compra. Si encuentras algún problema con un producto, por favor, contacta a tu vendedor/a comunitario para resolver el inconveniente."
        />
        <Accordion
          question="¿Es seguro ingresar los números de mis tarjetas en la app?"
          answer="Sí, es seguro. Utilizamos una pasarela de pago certificada y encriptación avanzada para proteger los datos de tu tarjeta de crédito. Tu información está segura y solo se utiliza para procesar la transacción."
        />
      </div>
    </section>
  );
};
