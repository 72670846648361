import VerticalLogo from "components/common/vertical-logo";
import { FootLink } from "components/common/foot-link";

export const Footer = () => {
  return (
    <footer className="bg-[#F2ECE6] py-10 md:flex md:flex-row md:justify-between md:px-24">
      <VerticalLogo />
      <div className="my-8">
        <ul className="text-center md:text-right">
          <FootLink
            text="Sobre Canasta Ahorro"
            link="https://canastaahorro.cl/#sobre-canasta-ahorro"
          />
          <FootLink
            text="Cómo empezar"
            link="https://canastaahorro.cl/#como-empezar"
          />
          <FootLink
            text="Nuestro equipo"
            link="https://canastaahorro.cl/#nuestro-equipo"
          />
          <FootLink
            text="Vende Canastas"
            link="https://canastaahorro.cl/#quiero-vender"
          />
          <FootLink
            text="Política de privacidad"
            link="https://canastaahorro.cl/politica-de-privacidad/"
          />
        </ul>
      </div>
    </footer>
  );
};
