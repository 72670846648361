export default function HorizontalLogo() {
  return (
    <div className="flex flex-col space-y-10 items-center justify-center ">
      <div className="flex flex-row">
        <a
          href="https://canastaahorro.cl/"
          target="_blank"
          aria-label="Canasta Ahorro (abre en otra pestaña)"
        >
          <img src="/img/new_logo_horizontal.svg" className="max-w-[150px]" />
        </a>
      </div>
    </div>
  );
}
